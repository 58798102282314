:root {
    --bg-color: #FFCCED;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@font-face {
    font-family: 'GraphikRegular';
    src: url('./assets/fonts/GraphikLCG-Regular.woff2') format('woff2'),
        url('./assets/fonts/GraphikLCG-Regular.woff2') format('woff');
}

@font-face {
    font-family: 'GraphikMedium';
    src: url('./assets/fonts/GraphikLCG-Medium.woff2') format('woff2'),
        url('./assets/fonts/GraphikLCG-Medium.woff2') format('woff');
}

@font-face {
    font-family: 'GraphikBold';
    src: url('./assets/fonts/GraphikLCG-Bold.woff2') format('woff2'),
        url('./assets/fonts/GraphikLCG-Bold.woff2') format('woff');
}

// Fluid typography
// fluid typography font-size:
// https://css-tricks.com/snippets/css/fluid-typography/
// calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 10px;
    //scroll-behavior: smooth;
    font-family: 'GraphikRegular', 'Helvetica', 'Arial', sans-serif;
}

@media screen and (max-width: 374px) {
    html {
        font-size: 10px;
    }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
    html {
        font-size: calc(10px + 10 * (100vw - 375px) / 392);
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    html {
        font-size: calc(8px + 2.7 * (100vw - 768px) / 255);
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    html {
        font-size: calc(7.4px + 2 * (100vw - 1024px) / 256);
    }
}

@media (min-width: 1280px) and (max-width: 1439px) {
    html {
        font-size: calc(9px + 0.5 * (100vw - 1280px) / 160);
    }
}

@media (min-width: 1440px) {
    html {
        font-size: calc((10px * (100vw - 1600px) / 240));
    }
}

body {
    font-size: 1.6rem !important;
    line-height: 2.4rem !important;
}

// End Typography

.App {
    display: grid;
    align-items: center;
    justify-content: center;
    padding: 5rem 6rem 6rem 3.2rem;
    overflow: hidden;
    min-height: 100vh;
    grid-template-columns: 12.5rem 1fr 1fr;
    // transition: grid-template ease-out 0.25s;
    background: var(--bg-color);

    &.app-full {
        grid-template-columns: 12.5rem 1fr 0fr;

        .background-circle {
            width: 53rem;
            height: 53rem;

            @media (max-width: 1024px) {
                width: 30rem;
                height: 30rem;
                left: 50%;
                transform: translateX(-50%);
                top: calc(50% - 18.5rem);
            }
        }

        @media (max-width: 1024px) {
            grid-template-columns: 1fr;
        }
    }

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        align-items: flex-start;
        padding: 2rem 2rem 12rem 2rem;
    }
}


.sidebar {
    position: relative;

    @media (max-width: 1024px) {
        margin-bottom: 3rem;
        width: 100%;
    }

    &__list {
        list-style-type: none;
        padding-left: 0;

        @media (max-width: 1024px) {
            display: flex;
            justify-content: space-between;

        }
    }

    &__line {
        top: 0.7rem;
        left: 1.5px;
        position: absolute;
        height: 0;
        width: 1px;
        background: #000;
        transition-duration: .4s;
        transition-property: height;
        transition-timing-function: ease-in-out;

        @media (max-width: 1024px) {
            display: none;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 2rem;
        font-family: "GraphikMedium";
        color: #ffffff;
        transition-delay: .2s;
        transition-duration: .25s;
        transition-property: all;
        transition-timing-function: ease-in-out;
        cursor: pointer;

        @media (max-width: 1024px) {
            padding-left: 0;
            text-align: center;
        }

        &:hover {
            color: #00000066;
            transition: color .3s;
        }

        &.disabled {
            pointer-events: none;
        }

        &+.sidebar__link {
            margin-top: 5rem;

            @media (max-width: 1024px) {
                margin-top: 0;
                margin-left: 2rem;
            }
        }

        // &:not(:first-child) {
        //     &::after {
        //         content: '';
        //         top: -5.9rem;
        //         left: 1.5px;
        //         position: absolute;
        //         height: 0;
        //         width: 1px;
        //         background: #000;
        //         transition-duration: .4s;
        //         transition-property: height;
        //         transition-timing-function: ease-in-out;
        //     }
        // }

        &::before {
            background-color: #000;
            border-radius: 50%;
            content: "";
            display: block;
            height: 5px;
            width: 5px;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            transition-delay: .2s;
            transition-duration: .25s;
            transition-property: opacity;
            transition-timing-function: ease-in-out;

            @media (max-width: 1024px) {
                content: none;
            }

        }

        &.active {
            color: #000;

            &:not(:first-child) {
                &::after {
                    height: 6.6rem;
                }
            }

            &::before {
                opacity: 1;
            }
        }

        &-text {
            @media (max-width: 1024px) {
                // display: none;
            }
        }

        span {
            text-transform: uppercase;
            font-size: 0.9rem;
            line-height: 1.5;
            letter-spacing: 1.4px;

            @media (max-width: 1024px) {
                font-size: 0.7rem;
            }

        }
    }
}


.form-wrapper {
    position: relative;
    width: 0;
    transition: all .3s ease-in;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;

    &.visible {
        visibility: visible;
        opacity: 1;
        // width: fit-content;

    }

    @media (max-width: 1024px) {

        margin-bottom: 6rem;
        margin-top: 3rem;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 47.5rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: .3s all;
    pointer-events: none;

    // margin-right: 10rem;
    @media (max-width: 1024px) {
        width: 100%;
        position: relative;
        top: 0;
        transform: none;
        display: none;
    }

    &.visible {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
        display: flex;
    }

    &__title {
        font-size: 5rem;
        line-height: 1;
        font-weight: 500;
        letter-spacing: -.2px;
        margin-bottom: 5rem;
        font-family: "GraphikMedium";

        @media (max-width: 1024px) {
            font-size: 3rem;
            margin-bottom: 2rem;
        }
    }


    .form__control {
        height: 5rem;
        font-size: 1.6rem;
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #b3b3b3;
        color: #000;
        box-shadow: none;
        cursor: pointer;

        &:hover {
            border-color: transparent;
            border-bottom: 1px solid #000;

            .form__dropdown-indicator {
                opacity: 1;
            }
        }

        &:focus {
            outline: none;
            border: none;
        }

        .form__value-container {
            padding-left: 0;
        }

        .form__placeholder {
            color: #000;
        }

    }

    .form__menu {
        box-shadow: 0 5px 15px #00000026;
        margin-top: 0;

        .form__option {
            background-color: transparent;
            color: #000;
            transition: color .3s ease-in-out;
            cursor: pointer;

            &--is-selected {
                background-color: transparent;
                font-weight: 600;
            }

            &--is-focused {
                background-color: transparent;
                color: #ac8c75;
            }
        }
    }

    .form__dropdown-indicator {
        opacity: 0.5;
        transition: opacity .3s ease-in-out;

        svg {
            color: #000;
        }

        &:hover {
            opacity: 1;

            svg {
                color: #000;
            }
        }
    }
}

.form-group {
    display: grid;
    gap: 15px 30px;
    grid-template-columns: 1fr 5fr;
    margin-top: 1.6rem;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr 4fr;
    }

    .label {
        &+.label {
            margin-top: 0;
        }
    }
}

.label {
    position: relative;
    min-height: 5rem;
    font-size: 1.6rem;
    display: block;
    border-bottom: 1px solid #b3b3b3;
    transition: .3s all;
    display: flex;
    align-items: center;

    &+.label {
        margin-top: 1.6rem;
    }

    &.focused {
        border-bottom: 1px solid #000;
    }

    &:hover,
    &:focus {
        border-bottom: 1px solid #000;
    }

    // span {
    //     position: absolute;
    //     opacity: 0;
    //     transition: opacity .2s;
    //     line-height: 5rem;
    //     letter-spacing: 1.2px;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     left: 0;
    // }
}


.select {
    height: 100%;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    letter-spacing: 1.2px;
    font-weight: inherit;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    border-bottom: 1px solid #b3b3b3;
    height: 5rem;
    font-size: 1.6rem;

    &:hover,
    &:focus {
        border-bottom: 1px solid #000;
    }

    option {
        background: transparent;
    }
}



.input {
    height: auto;
    width: 100%;
    font-size: inherit;
    font-family: inherit;
    font-style: inherit;
    letter-spacing: 1.2px;
    background: none;
    font-weight: inherit;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 0;
    // height: fit-content;

    &:focus {
        &~span {
            opacity: 1;
        }
    }

    &::placeholder {
        opacity: 0.6;
    }

    &:focus {
        outline: none;
    }
}

.input-price-wrapper {
    display: flex;
    align-items: center;
    width: 10rem;
    max-width: fit-content;

    input {
        width: 19rem;
    }

    span {

        font-family: "GraphikMedium";
        font-size: 45px;
        font-weight: 500;
        letter-spacing: -.2px;
        line-height: 1.1;
    }

    label {
        height: auto;
        border-bottom: none;
        font-size: 45px;
        font-weight: 500;
        letter-spacing: -.2px;
        line-height: 1.1;
        font-family: 'GraphikMedium';

        &:hover {
            border-bottom: none;
        }
    }
}

.next-page {
    position: absolute;
    bottom: 5rem;
    right: 5rem;
    background-color: #000;
    box-shadow: none;
    font-size: 1.1rem;
    letter-spacing: 1.2px;
    width: 13rem;
    height: 13rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 50%;
    color: #fff;
    box-shadow: 0 0 36px 0 #0000001a, 0 24px 36px 0 #0003;
    transition-duration: .2s;
    transition-property: transform opacity;
    transition-timing-function: ease-out;
    cursor: pointer;
    border: none;

    &.disabled {
        background-color: #b3b3b3;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
    }

    &:hover {
        transform: scale(1.1);
    }

    @media (max-width: 1024px) {
        position: fixed;
        bottom: 1rem;
        left: 50%;
        border-radius: 5px;
        width: 90%;
        height: 6rem;
        transform: translateX(-50%);
        font-size: 1.1rem;
        line-height: 1.5;

        &:hover {
            opacity: 0.8;
            transform: translateX(-50%);
        }
    }


}

.back-page {
    position: absolute;
    bottom: 5rem;
    right: 20rem;
    background-color: #fff;
    box-shadow: none;
    font-size: 1rem;
    letter-spacing: 1.2px;
    width: 7rem;
    height: 7rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 50%;
    color: #000;
    box-shadow: 0 0 36px 0 #0000000e, 0 24px 36px 0 rgba(0, 0, 0, 0.12);
    transition-duration: .2s;
    transition-property: opacity transform;
    transition-timing-function: ease-out;
    cursor: pointer;
    border: none;
    opacity: 0;

    &.visible {
        opacity: 1;
    }

    &.disabled {
        background-color: #b3b3b3;
        color: #fff;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
    }

    &:hover {
        transform: scale(1.1);
    }

    @media (max-width: 1024px) {
        position: fixed;
        bottom: 8rem;
        left: 50%;
        border-radius: 5px;
        width: 50%;
        height: 3rem;
        font-size: 1rem;
        transform: translateX(-50%);

        &:hover {
            opacity: 0.8;
            transform: translateX(-50%);
        }
    }

}

.header {
    height: 100%;
    align-items: center;
    display: flex;
    position: relative;

    @media (max-width: 1024px) {
        height: auto;
    }
}

.page {
    position: absolute;
    top: 0;
    left: 2rem;
    color: #000;
    font-size: 3.5rem;
    font-weight: 500;
    letter-spacing: -.4px;
    line-height: 1.1;
    font-family: 'GraphikMedium';

    @media (max-width: 1024px) {
        display: none;
    }
}

.d-flex {
    display: flex;
    gap: 1rem;
}

.main {
    position: relative;
    transition: all ease-in-out 0.25s;

    @media (max-width: 1024px) {
        display: flex;
        justify-content: center;
        margin-bottom: 5rem;
    }

    &__title {
        transition: .3s all;
        position: relative;
        right: -50%;
        height: auto;
        font-size: 5.1rem;
        font-weight: 500;
        width: fit-content;
        line-height: 4.3rem;
        font-family: 'GraphikMedium';
        margin-bottom: 2rem;

        &--mobile {
            display: none;
        }

        @media (max-width: 1024px) {
            &--mobile {
                display: block;
                margin-bottom: 3rem;
            }

            display: none;
            font-size: 3rem;
            line-height: 2.5rem;
            right: 0;
            margin-bottom: 0;
        }
    }
}

.final {
    font-size: 1.6rem;
    line-height: 1.4;

    &__header {
        border-bottom: 1px solid #000000b3;
        border-top: 1px solid #000000b3;
        padding: 5rem 0 4rem;
        margin-bottom: 4rem;
    }

    &__name {
        font-size: 2.5rem;
        margin-bottom: 2.5rem;
        line-height: 1;
        font-family: "GraphikBold";
    }

    &__text {
        font-size: 1.6rem;
        line-height: 1.6;
    }

    &__from {
        font-size: 1.8rem;
        margin-top: 3.5rem;
        font-family: "GraphikMedium";
    }

    &__subtitle {
        font-family: "GraphikMedium";
        margin-bottom: 0.7rem;
    }

    &__block {
        &+.final__block {
            margin-top: 2rem;
        }
    }

    &__price {
        &-subtitle {
            font-family: "GraphikMedium";
            margin-bottom: 0;
        }

        font-size: 2.5rem;
        font-family: "GraphikMedium";
        line-height: 1.4;
    }
}

.hidden {
    opacity: 0;
    visibility: hidden;
    transition: .3s all;
}

.background-circle {
    position: absolute;
    width: 43rem;
    height: 43rem;
    border-radius: 50%;
    transition: all ease-in-out 0.25s;
    left: calc(50% - 26.5rem);
    top: calc(50% - 26.5rem);
    background-color: rgba(220, 77, 233, 0.05);
    background-image: linear-gradient(124.56deg, rgba(239, 80, 220, 0.16) 18.61%, rgba(223, 56, 145, 0) 82.11%);


}

.slick-slider {
    max-width: 110rem;
    width: 110rem;
    margin: 0 auto;
    transition: transform .3s;

    @media (max-width: 1024px) {
        max-width: 30rem;
        width: 30rem;
    }

    &.slide-hidden {
        @media (max-width: 1024px) {
            transform: translateX(0);

            .slick-next,
            .slick-prev {
                &::before {
                    content: none;
                }
            }
        }

        transform: translateX(-20rem);

        .slick-slide {
            opacity: 0;
            pointer-events: none;

            &.slick-active {
                opacity: 1;
            }
        }

        .slick-prev,
        .slick-next {
            pointer-events: none;
        }
    }

    .slick-list {
        overflow-y: visible;
    }
}

.slick-prev,
.slick-next {
    width: 12rem;
    height: 100%;
    z-index: 1;

    &::before {
        content: none;
    }
}

.slick-next {
    cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41Ij48cGF0aCBkPSJtMjAgMTkgOS04Ljk3OEwyMCAxbTkgOUgwIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iYSI+PHBhdGggZmlsbD0iI2ZmZiIgdHJhbnNmb3JtPSJyb3RhdGUoLTE4MCAxNSAxMCkiIGQ9Ik0wIDBoMzB2MjBIMHoiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4="), pointer;
    right: 36rem;

    @media (max-width: 1024px) {
        cursor: pointer;

        &::before {
            content: "→";
        }
    }

    @media (max-width: 1024px) {
        right: -3rem;
    }
}

.slick-prev {
    left: 36rem;
    cursor: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgY2xpcC1wYXRoPSJ1cmwoI2EpIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMS41Ij48cGF0aCBkPSJNMTAgMSAxIDkuOTc4IDEwIDE5bS05LTloMjkiLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJhIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDMwdjIwSDB6Ii8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+"), pointer;

    @media (max-width: 1024px) {
        cursor: pointer;

        &::before {
            content: "←";
        }
    }

    @media (max-width: 1024px) {
        left: -3rem;
    }
}

.slider-wrapper {
    transition: all ease-in-out 0.25s;

    &.mini {
        width: 70rem;
        overflow: hidden;

        @media (max-width: 1024px) {
            width: 30rem;
        }
    }
}

small {
    position: absolute;
    bottom: -2rem;

    // &.hidden {
    //     height: 0;
    // }
}

.slick-slide {
    transition: all .4s;
    cursor: pointer;
    display: none;

    img {
        // border-radius: 50%;
        transition: all .4s;
        transform: scale(0.8);
    }

    div {
        display: flex;
    }

    @media (max-width: 1024px) {

        img {

            transform: scale(1);
        }
    }

    &.slick-active {
        cursor: default;
        display: block;

        img {
            // border-radius: 0;
            transform: scale(1);
        }
    }
}

.slider-img {
    img {
        width: 100%;
        margin: 0 auto;

        @media (max-width: 1024px) {
            width: 95%;
        }
    }
}